<template>
  <div>
    <ExtendedView
      :tabs="tabs"
      :selected-object="selectedCustomer"
      @clearSelectedObject="selectedCustomer = null"
    >
      <template v-slot:list="{ item }">
        <CustomerList
          v-if="item.key === 'active'"
          :customers="activeCustomers"
          @selectedCustomer="selectCustomer"
          :fixed-filters="activeCustomerFilters"
          :loading="loading"
          :call-to-actions="callToActions"
        />
        <CustomerList
          v-if="item.key === 'inactive'"
          :customers="inactiveCustomers"
          @selectedCustomer="selectCustomer"
          :fixed-filters="inactiveCustomerFilters"
          :loading="loading"
          :call-to-actions="callToActions"
        />
        <CustomerList
          v-if="item.key === 'invoiceable'"
          :customers="invoiceableCustomers"
          @selectedCustomer="selectCustomer"
          :fixed-filters="invoiceableFilters"
          :loading="loading"
          :call-to-actions="callToActions"
        />
        <CustomerList
          v-if="item.key === 'deliver_invoice'"
          :customers="deliverInvoiceCustomers"
          @selectedCustomer="selectCustomer"
          :fixed-filters="deliverInvoiceFilters"
          :loading="loading"
          :call-to-actions="callToActions"
        />
        <CustomerList
          v-if="item.key === 'inexchange'"
          :customers="inexchangeCustomers"
          @selectedCustomer="selectCustomer"
          :fixed-filters="deliverInExchangeFilters"
          :loading="loading"
          :call-to-actions="callToActions"
        />
        <CustomerList
          v-if="item.key === 'exclusive'"
          :customers="exclusiveCustomers"
          @selectedCustomer="selectCustomer"
          :fixed-filters="exclusiveFilters"
          :loading="loading"
          :call-to-actions="callToActions"
        />
        <CustomerList
          v-if="item.key === 'without_ssn'"
          :customers="withoutSSNCustomers"
          @selectedCustomer="selectCustomer"
          :fixed-filters="withoutSSNFilters"
          :call-to-actions="callToActions"
        />
      </template>
      <template slot="profile">
        <CustomerProfile
          class="mr-5"
          v-if="selectedCustomer !== null && selectedCustomer !== {}"
          :customer="selectedCustomer"
        />
      </template>
    </ExtendedView>
  </div>
</template>

<script>
import ExtendedView from "@/components/common/ExtendedView"
import CustomerList from "@/components/CustomerList"
//import CustomerDialog from "@/components/CustomerDialog"
import CustomerProfile from "@/components/customer/CustomerProfile"

export default {
	name: 'Customers',
	components: {
		//CustomerDialog,
		CustomerList,
		CustomerProfile,
		ExtendedView
	},
	data() {
		return {
			dialog: false,
			customer: {},
			customerUUID: null,
			searchFields: {},
			nameSearch: {},
			phoneSearch: {},
			emailSearch: {},
			ssnSearch: {},
			labelSearch: {},
			customersWithNoFOP: null,
			selectedCustomer: null,
			loading: false,
			callToActions: [
				{
					title: this.$t('addCustomer'),
					callback: this.showCustomerDialog
				}
			],
			activeCustomerFilters: [
				{
					key: 'active',
					value: true,
					operator: 'equals',
					type: 'boolean'
				}
      		],
			inactiveCustomerFilters: [
				{
					key: 'active',
					value: false,
					operator: 'equals',
					type: 'boolean'
				}
      		],
			invoiceableFilters: [
				{
					key: 'invoiceable',
					value: true,
					operator: 'equals',
					type: 'boolean'
				}
      		],
			deliverInvoiceFilters: [
				{
					key: 'deliverInvoice',
					value: true,
					operator: 'equals',
					type: 'boolean'
				}
      		],
			exclusiveFilters: [
				{
					key: 'exclusive',
					value: true,
					operator: 'equals',
					type: 'boolean'
				}
      		],
			deliverInExchangeFilters: [
				{
					key: 'deliverToInExchange',
					value: true,
					operator: 'equals',
					type: 'boolean'
				}
      		],
			withoutSSNFilters: [
				{
					key: 'ssn',
					value: null,
					operator: 'equals',
					type: 'text'
				}
      		]
		}
	},
	methods: {
		selectCustomer(customer) {
			this.selectedCustomer = customer
		},
		exportCustomers() {
			switch(this.selectedAppBarTab) {
				case 'active':
					this.$store.commit('updateExportObjects', this.activeCustomers)
					break
				case 'inactive':
					this.$store.commit('updateExportObjects', this.inactiveCustomers)
					break
				case 'exclusive':
					this.$store.commit('updateExportObjects', this.exclusiveCustomers)
					break
				case 'invoiceable':
					this.$store.commit('updateExportObjects', this.invoiceableCustomers)
					break
				case 'deliver_invoice':
					this.$store.commit('updateExportObjects', this.deliverInvoiceCustomers)
					break
				case 'without_ssn':
					this.$store.commit('updateExportObjects', this.withoutSSNCustomers)
					break
				case 'inexchange':
					this.$store.commit('updateExportObjects', this.inexchangeCustomers)
					break
				case 'nofop':
					this.$store.commit('updateExportObjects', this.noFOPCustomers)
					break
				default:
					this.$store.commit('updateExportObjects', this.customers)
					break
			}
		},
		addAnother(value) {
			if(value) {
				this.customer = {}
				if(this.defaultCountry && this.defaultCountry.code) {
					this.customer['country'] = this.defaultCountry.code
				}
				this.showCustomerDialog()
			}
		},
		created(customerUUID) {
			//this.$store.commit('addCustomer', customer)
			//this.$router.push({path: '/customers/' + customerUUID})
			this.customerUUID = customerUUID
			//let customer = this.customers.find(customer => customer.uuid === customerUUID.uuid)
			this.customer = {}
			if(this.defaultCountry && this.defaultCountry.code) {
				this.customer['country'] = this.defaultCountry.code
			}
			this.dialog = false
		},
		updated(customerUUID) {
			let customer = this.customers?.find(c => c?.uuid === customerUUID.uuid)
			const message =  `${this.$t('customerInformationFor')} ${customer?.address?.name_line} ${this.$t('wasSuccessfullyUpdated')}`
			this.$store.commit('updateActionSuccess', {
				message: this.$t('success'),
				subMessage: message
			})
			this.dialog = false
		},
		dialogChanged(dialog) {
			this.dialog = dialog
		},
		showCustomerDialog: function() {
			if(this.customer.name_line === undefined) {
				this.customer.name_line = ''
			}
			this.$store.commit('updateDataToMutate', {
				objectToMutate: this.customer,
				formComponent: 'components/customer/CustomerForm', 
				saveCallback: this.createCustomer, 
				title:  this.$t('createCustomer') 
			})
		},
		getCustomers() {
			return this.$store.dispatch('getCustomersFragments', {})
      // return this.$store.dispatch('getCustomers', {})
		}, 
		createCustomer(newCustomer) {
			if(newCustomer['currency']) {
				newCustomer['currency'] = { code: newCustomer['currency']	}
			}
			if(newCustomer['passport_expiration_date']) {
				newCustomer['passport_expiration_date'] = newCustomer['passport_expiration_date'] + ""
			}
			this.$store.dispatch('createCustomer', newCustomer).then(result => {
				if(result) {
					let message = this.$t('customer') + ' ' + newCustomer.address.name_line + ' ' + this.$t('successfullyAdded')
					this.$store.commit('updateActionSuccess', {
						message: this.$t('success'),
						subMessage: message
           			 })
				} else {
					this.$store.commit('updateActionError', {
						message: this.$t('error'),
						subMessage: this.$t('couldNotCreateCustomer')
          			})
				}
			})
			.catch(error => {
          		this.$store.commit('updateActionError', {
					message: this.$t('error'),
					subMessage: this.$t('couldNotCreateCustomer')
				})
			})	
		}
	},
	watch: {
		customers: function(newCustomers, oldCustomers) {
			if(newCustomers !== null && oldCustomers === null) {
				this.$store.commit('updateAppBarTabs', this.tabs)
			}
		},
		selectedAppBarTab: function(selectedAppBarTab) {
			if(selectedAppBarTab === 'nofop') {
				this.$store.dispatch('getFormsOfPayment').then(() => {
					this.$worker.run((customers, formsOfPayment) => {
						return customers.filter(customer => !formsOfPayment.some(f => f.customer.customer_id === customer.customer_id ))
				}, [this.$store.state.customers, this.$store.state.formsOfPayment]).then(result =>{
						this.customersWithNoFOP = result
					})
				})
			}
		},
	},
	computed: {
		organization() {
			return this.$store.state.organization
		},
		inIframe() {
			return this.$store.state.inIframe
		},
		inApp() {
			return this.$store.state.inApp
		},
		defaultCountry() {
			return this.$store.state.defaultCountry
		},
		defaultCurrency() {
			return this.$store.state.defaultCurrency
		},
		query() {
			return this.$store.state.searchQuery
		},
		activeCustomerCount() {
			return this.activeCustomers !== null ? this.activeCustomers.length : 0
		},
		inactiveCustomerCount() {
			return this.inactiveCustomers !== null ? this.inactiveCustomers.length : 0
		},
		exclusiveCustomerCount() {
			return this.exclusiveCustomers !== null ? this.exclusiveCustomers.length : 0
		},
		invoiceableCustomerCount() {
      		return this.invoiceableCustomers !== null ? this.invoiceableCustomers.length : 0
		},
    	deliverInvoiceCustomerCount() {
      		return this.deliverInvoiceCustomers !== null ? this.deliverInvoiceCustomers.length : 0
		},
		withoutSSNCustomerCount() {
			return this.withoutSSNCustomers !== null ? this.withoutSSNCustomers.length : 0
		},
		inexchangeCustomersCount() {
			return this.inexchangeCustomers !== null ? this.inexchangeCustomers.length : 0
		},
		noFOPCustomersCount() {
			return this.noFOPCustomers !== null ? this.noFOPCustomers.length : 0
		},
		tabs() {
			return [
				{
					title: this.$t('active'),
					key: 'active'
				},
				{
					title: this.$t('exclusive'),
					key: 'exclusive'
				},
				{
					title: this.$t('invoicable'),
					key: 'invoiceable'
				},
				{
					title: this.$t('deliverInvoice'),
					key: 'deliver_invoice'
				},
				{
					title: 'InExchange',
					key: 'inexchange'
				},
				{
					title: this.$t('inactive'),
					key: 'inactive',
				},
				{
					title: this.$t('withoutSSN'),
					key: 'without_ssn',
				},
				{
					title: this.$t('noFop'),
					key: 'nofop',
				}
			]
		},
		selectedAppBarTab() {
			return this.$store.state.selectedAppBarTab
		},
		activeCustomers() {

			if(this.customers === null || this.customers === undefined) {
				return null
			}

			return this.customers.filter(customer => {
				return !!customer.active
			})
		},
		exclusiveCustomers() {

			if(this.customers === null || this.customers === undefined) {
				return null
			}

			return this.customers.filter(customer => {
				return !!(customer.active && customer.exclusive)
			})
		},
		invoiceableCustomers() {

			if(this.customers === null || this.customers === undefined) {
				return null
			}

			return this.customers.filter(customer => {
				return !!(customer.active && customer.invoiceable)
			})
		},
		deliverInvoiceCustomers() {

			if(this.customers === null || this.customers === undefined) {
				return null
			}

			return this.customers.filter(customer => {
				return !!(customer.active && customer.deliverInvoice)
			})
		},
		inexchangeCustomers() {

			if(this.customers === null || this.customers === undefined) {
				return null
			}

			return this.customers.filter(customer => {
				return !!(customer.active && customer.invoiceable && customer.deliverInvoiceToInExchange)
			})
		},
		inactiveCustomers() {

			if(this.customers === null || this.customers === undefined) {
				return null
			}

			return this.customers.filter(customer => {
				return !customer.active
			})
		},
		withoutSSNCustomers() {

			if(this.customers === null || this.customers === undefined) {
				return null
			}

			return this.customers.filter(customer => {
				return !!(customer.ssn === null || customer.ssn.length !== 10)
			})
		},
		customers() {
			/*
			if(this.query !== null && this.query.length > 0 ) {

				const query = this.query.reduce((accumulator, item) => accumulator + item.value, '')

				// let foundCustomers = []
				// for(let i in this.searchFields) {
				// 	if(i.includes(this.query.toLowerCase())) {
				// 		foundCustomers.push.apply(foundCustomers, this.searchFields[i])
				// 	}
				// }
				// return foundCustomers

				return this.$store.state.customers.filter(function(customer) {

					if(customer.address && customer.address.name_line) {
						if(customer.address.name_line.toLowerCase().includes(query.toLowerCase())) {
							return true
						}
					}

					if(customer.phone && customer.phone.includes(query)) {
						return true
					}

					if(customer.email && customer.email.includes(query)) {
						return true
					}

					if(customer.ssn) {
						let ssnQuery = query
						ssnQuery = ssnQuery.replace('-', '')

						if(customer.ssn.includes(ssnQuery)) {
							return true
						}
					}
					if(customer.label && customer.label.includes(query)) {
						return true
					}
					return false
				}.bind(this))
			}
			*/

			if(this.$store.state.customers === null) {
				return null
			}

			return this.$store.state.customers.filter(customer => customer !== null)
		},
		noFOPCustomers() {
			if(this.customersWithNoFOP !== null && this.customersWithNoFOP !== undefined){
				return this.customersWithNoFOP
			}
			return null
		}
	},
	updated() {
		this.$store.commit('updateAppBarTabs', this.tabs)
	},
	mounted() {
		this.$store.commit('updateAppBarTabs', this.tabs)
		this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
	},
    beforeCreate() {
      if (!this.$store.state.customers) {
		this.$store.dispatch('getCustomersFragments', {})
      }
    },
	created() {
		this.$store.commit('updateAppBarExport', {
			color: 'pink',
			callback: this.exportCustomers
		})

		if(this.defaultCountry && this.defaultCountry.code && !this.customer['country']) {
			this.customer['country'] = this.defaultCountry.code
		}
		if(this.defaultCurrency && this.defaultCurrency.code && !this.customer['currency']) {
			this.customer['currency'] = this.defaultCurrency.code
		}
	},
	destroyed() {
		this.$store.commit('updateAppBarTabs', [])
	}
}
</script>
