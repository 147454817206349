<template>
  <div
    v-if="customer !== null"
    :key="customer.uuid"
    class="grey lighten-3"
  >
    <v-card
      no-gutters
      :key="customer.uuid"
      class="mb-1"
      outlined
      rounded="lg"
    >
      <v-toolbar
        flat
        class="px-0 customer-toolbar"
      >
        <v-tooltip
          top
          v-if="customer.exclusive"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              :href="'mailto:' + customer.email"
            >
              <v-icon color="orange">
                mdi-star
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('exclusive') }}</span>
        </v-tooltip>
        <v-tooltip
          top
          v-else
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon color="orange">
                mdi-star-outline
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('notExclusive') }}</span>
        </v-tooltip>

        <v-toolbar-title
          v-if="customer.address.name_line"
          class="font-weight-bold"
        >
          <div>{{ customer.address.name_line }}</div>
        </v-toolbar-title>
        <v-toolbar-title
          v-else
          class="font-weight-bold red--text"
        >
          {{ $t('nameRequired') }}
        </v-toolbar-title>
        <v-spacer />
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn
                v-if="!updateCustomer && !customerFormValid"
                color="success"
                class="my-4"
                width="135px"
                @click="triggerUpdateCustomer"
                elevation="0"
                disabled
              >
                {{ $t('save') }}
              </v-btn>
            </div>
          </template>
          {{ $t('missingRequiredValuesOrValuesAreIncorrect') }}
        </v-tooltip>
        <v-btn
          v-if="!updateCustomer && customerFormValid"
          color="success"
          class="my-4"
          width="135px"
          @click="triggerUpdateCustomer"
          elevation="0"
        >
          {{ $t('save') }}
        </v-btn>
        <v-btn
          v-if="updateCustomer"
          color="success"
          disabled
          width="165px"
        >
          <v-progress-circular
            indeterminate
          />
          <v-icon />
        </v-btn>
      </v-toolbar>
      <v-divider />
      <v-toolbar flat>
        <v-tooltip
          top
          v-if="customer.invoiceable"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon color="green">
                mdi-text-box-check-outline
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('invoicable') }}</span>
        </v-tooltip>
        <v-tooltip
          top
          v-else
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>
                mdi-text-box-remove-outline
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('notInvoicable') }}</span>
        </v-tooltip>
        <v-tooltip
          top
          v-if="customer.deliverInvoice"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon color="green">
                mdi-email-send-outline
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('invoiceWillBeDelivered') }}</span>
        </v-tooltip>
        <v-tooltip
          top
          v-else
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>
                mdi-email-remove-outline
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('invoicesNotDelivered') }}</span>
        </v-tooltip>
        <v-btn
          v-if="customer.email"
          text
          small
          :href="'mailto:' + customer.email"
        >
          {{ customer.email }}
        </v-btn>
        <span
          v-else
          class="mx-5"
        >
          ---
        </span>
        <v-spacer />
        <v-btn
          :href="editUrl"
          target="_blank"
          rel="noopener noreferrer"
          outlined
          color="green"
          width="135px"
        >
          <span>{{ $t('oldProfile') }}</span>
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-row dense>
      <v-col
        cols="12"
        md="12"
      >
        <v-row>

          <v-col
            cols="6"
            md="6"
            class="pt-0 pb-1 pr-1"
          >
            <TransientDash
              :title="$t('revenue')"
              namespace="gross_revenue"
              :start-time-in-millis="startTimeInMillis"
              :end-time-in-millis="endTimeInMillis"
              :config="dashConfig"
              rounded="lg"
              height="32px"
            />
          </v-col>
          <v-col
            cols="6"
            md="6"
            class="pt-0 pb-1 px-1 "
          >
            <TransientDash
              :title="$t('averagePayment')"
              namespace="average_payment_amount"
              :start-time-in-millis="startTimeInMillis"
              :end-time-in-millis="endTimeInMillis"
              :config="dashConfig"
              rounded="lg"
              height="32px"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-card
      outlined
      class="mb-2"
      rounded="lg"
    >
      <v-tabs
        v-model="tab"
      >
        <v-tab href="#generalInfo">
          {{ $t('generalInfo') }}
        </v-tab>
        <v-tab href="#payments">
          {{ $t('payments') }}
        </v-tab>
        <v-tab href="#orders">
          {{ $t('orders') }}
        </v-tab>
        <v-tab href="#invoices">
          {{ $t('invoices') }}
        </v-tab>
        <v-tab href="#giftcards">
          {{ $t('giftcards') }}
        </v-tab>
        <v-tab href="#subscriptions">
          {{ $t('subscriptions') }}
        </v-tab>
        <v-tab href="#cardsOnFile">
          {{ $t('cardsOnFile') }}
        </v-tab>
        <v-tab href="#bookings">
          {{ $t('bookings') }}
        </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items
      class="grey lighten-3"
      v-model="tab"
    >
      <v-tab-item value="generalInfo">
        <CustomerForm
          :value="customerToUpdate"
          :show-save-button="true"
          :trigger-update="updateCustomer"
          @valid="setFormValidity"
          @finishedUpdating="customerUpdated"
        />
      </v-tab-item>
      <v-tab-item value="orders">
        <v-card
          class="mt-3"
          rounded="lg"
        >
          <OrderList
            :load-items-callback="getOrders"
            :fixed-filters="fixedOrdersFilters"
            @selectOrder="selectOrder"
            :call-to-actions="orderActions"
          />
        </v-card>
      </v-tab-item>
      <v-tab-item value="payments">
        <v-card
          outlined
          rounded="lg"
        >
          <PaymentList
            @selectPayment="selectPayment"
            :load-items-callback="getPayments"
            :fixed-filters="fixedPaymentFilters"
          />
        </v-card>
      </v-tab-item>
      <v-tab-item value="invoices">
        <v-card
          outlined
          rounded="lg"
        >
          <InvoiceList
            :load-items-callback="getInvoices"
            :fixed-filters="fixedInvoicesFilters"
            @selectInvoice="selectInvoice"
          />
        </v-card>
      </v-tab-item>
      <v-tab-item value="subscriptions">
        <v-card
          outlined
          rounded="lg"
        >
          <SubscriptionList
            :load-items-callback="getSubscriptions"
            :fixed-filters="fixedSubscriptionsFilters"
            @selectSubscription="selectSubscription"
            :call-to-actions="subscriptionActions"
          />
        </v-card>
      </v-tab-item>
      <v-tab-item value="giftcards">
        <v-card
          outlined
          rounded="lg"
        >
          <FormsOfPaymentList
            :load-items-callback="getFormsOfPayment"
            :fixed-filters="fixedGiftcardsFilters"
            @selectFormOfPayment="selectFormOfPayment"
            :call-to-actions="giftcardActions"
          />
        </v-card>
      </v-tab-item>
      <v-tab-item value="coupons">
        <v-card
          outlined
          rounded="lg"
        >
          <SubscriptionList
            :subscriptions="subscriptions"
            @selectSubscription="selectSubscription"
          />
        </v-card>
      </v-tab-item>
      <v-tab-item value="cardsOnFile">
        <v-card
          outlined
          rounded="lg"
        >
          <FormsOfPaymentList
            :load-items-callback="getFormsOfPayment"
            :fixed-filters="fixedCardsOnFileFilters"
            @selectFormOfPayment="selectFormOfPayment"
          />
        </v-card>
      </v-tab-item>
      <v-tab-item value="bookings">
        <v-card
          outlined
          rounded="lg"
        >
          <BookingListSimple
            :load-items-callback="getBookings"
            :fixed-filters="fixedBookingsFilters"
            @selectBooking="selectBooking"
          />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

import OrderList from '../OrderList.vue'
import PaymentList from '../PaymentList.vue'
import SubscriptionList from '../SubscriptionList.vue'
import BookingListSimple from '../booking/BookingListSimple.vue'
import InvoiceList from '../InvoiceList.vue'
import FormsOfPaymentList from '../formsOfPayment/FormOfPaymentList.vue'
import TransientDash from '../dash/TransientDash.vue'
import CustomerForm from '../customer/CustomerForm.vue'
export default {
  name: "CustomerProfile",
  props: {
    customer: {
      type: Object,
      default: () => null,
      required: false
    },
    uuid: {
      type: String,
      default: () => null,
      required: false
    }
  },
  components: {
    OrderList,
    PaymentList,
    SubscriptionList,
    BookingListSimple,
    InvoiceList,
    FormsOfPaymentList,
		TransientDash,
    CustomerForm
  },
  data() {
    return {
      tab: null,
      orders: null,
      payments: null,
      subscriptions: null,
      bookings: null,
      coupons: null,
      giftcards: null,
      invoices: null,
			startTimeInMillis: 0,
			endTimeInMillis: Date.now(),
      giftcardActions: [
        {
          title: this.$t('addGiftcard'),
          callback: this.addGiftcardDialog
        }
      ],
      subscriptionActions: [
        {
          title: this.$t('addSubscription'),
          callback: this.addSubscriptionDialog
        }
      ],
      orderActions: [
        {
          title: this.$t('addOrder'),
          callback: this.addOrderDialog
        }
      ],
      updateCustomer: false,
      customerFormValid: false
    }
  },
  computed: {
    customerToUpdate() {
      const customer = JSON.parse(JSON.stringify(this.customer))
      customer.name_line = this.customer.address.name_line
      customer.country = this.customer.address.country
      return customer
    },
		dashConfig() {
			return {
				customerUUID: this.customer.uuid
			}
		},
    fixedPaymentFilters() {
      return [
        {
          key: 'customer.uuid',
          value: this.customer.uuid,
          operator: 'equals'
        }
      ]
    },
    fixedInvoicesFilters() {
      return [
        {
          key: 'customer.uuid',
          value: this.customer.uuid,
          operator: 'equals'
        }
      ]
    },
    fixedSubscriptionsFilters() {
      return [
        {
          key: 'customer.uuid',
          value: this.customer.uuid,
          operator: 'equals'
        }
      ]
    },
    fixedOrdersFilters() {
      return [
        {
          key: 'customer.uuid',
          value: this.customer.uuid,
          operator: 'equals'
        }
      ]
    },
    customerName() {
      return this.customer.address.name_line
    },
    fixedGiftcardsFilters() {
      return [
        {
          key: 'customer.uuid',
          value: this.customer.uuid,
          operator: 'equals'
        },
        {
          key: 'paymentMethod',
          value: 'giftcard',
          operator: 'equals'
        }
      ]
    },
    fixedCardsOnFileFilters() {
      return [
        {
          key: 'customer.uuid',
          value: this.customer.uuid,
          operator: 'equals'
        },
        {
          key: 'paymentMethod',
          values: ['valitor_pay', 'commerce_valitor_fyrirtaekjagreidslur', 'borgun_rpg'],
          operator: 'contains'
        }
      ]
    },
    fixedBookingsFilters() {
      return [
        {
          key: 'customer.uuid',
          value: this.customer.uuid,
          operator: 'equals'
        }
      ]
    },
    editUrl() {
      return 'https://admin.salescloud.is/customers/'  + this.customer.customer_id
    }
  },
  methods: {
    getPayments({limit, skip, conditions}) {
        return this.$store.dispatch('getPayments', {limit: limit, conditions})
    },
    getInvoices({limit, skip, conditions}) {
        return this.$store.dispatch('getInvoices', {limit: limit, conditions})
    },
    getSubscriptions({limit, skip, conditions}) {
        return this.$store.dispatch('getSubscriptions', {limit: limit, conditions})
    },
    getOrders({limit, skip, conditions}) {
        return this.$store.dispatch('getOrders', {limit: limit, conditions})
    },
    getFormsOfPayment({limit, skip, conditions}) {
      return this.$store.dispatch('getFormsOfPayment', {limit: limit, conditions})
    },
    getBookings({conditions}) {
      return this.$store.dispatch('getBookingsByConditions', {conditions})
    },
    selectPayment(payment) {
      this.$store.commit('updateContextOverlay', {
        'component':  'components/payment/PaymentProfile',
        'props': {
          'payment': payment
        }
      })
    },
    selectInvoice(invoice) {
      this.$store.commit('updateContextOverlay', {
        'component':  'components/invoice/InvoiceProfile',
        'props': {
          'invoice': invoice
        }
      })
    },
    selectOrder(order) {
      this.$store.commit('updateContextOverlay', {
        'component':  'components/order/OrderProfile',
        'props': {
          'order': order
        }
      })
    },
    selectSubscription(subscription) {
      this.$store.commit('updateContextOverlay', {
        'component':  'components/subscriptions/SubscriptionProfile',
        'props': {
          'subscription': subscription
        }
      })
    },
    selectFormOfPayment(formOfPayment) {
      this.$store.commit('updateContextOverlay', {
        'component':  'components/formsOfPayment/FormOfPaymentProfile',
        'props': {
          'formOfPayment': formOfPayment
        }
      })
    },
    selectBooking(booking) {
        this.$store.commit('updateContextOverlay', {
          'component': 'components/booking/AddBookingCard',
          'props': {
            'bookingObject': booking,
            'bookingItem': booking.item.uuid,
            'bookingTime': booking.startsAtTime,
            'bookingDuration': ((booking.endsAtTime - booking.startsAtTime) / 60) // This needs to be divided by 60 to change value to minutes rather than seconds
          }
        })
    },
    resetCustomerData() {
      this.orders = null
      this.invoices = null
      this.payments = null
      this.subscriptions = null
      this.bookings = null
      this.coupons = null
      this.giftcards = null
      this.coupons = null
    },
    getCustomerData(customer) {
      this.$store.dispatch('getCustomerData', customer).then(customerData => {
        if(customerData !== null) {
          this.orders = customerData.orders
          this.payments = customerData.payments
          this.subscriptions = customerData.subscriptions
          this.bookings = customerData.bookings
          this.invoices = customerData.invoices
        }
      })
    },
    getCustomer() {
      this.$store.dispatch('getCustomerByUUID', this.uuid).then(customer => {
        this.customer = customer
      })
    },
    addGiftcardDialog() {
      this.$store.commit('updateGenericDialog', {
        props: {
          value: {
            customerName: this.customer.address.name_line,
            customerPhone: this.customer.phone,
            customerEmail: this.customer.email
          },
        },
        component: 'components/formsOfPayment/NewFormOfPaymentForm',
        title: this.$t('createGiftcard')
      })
    },
    triggerUpdateCustomer() {
      this.updateCustomer = true
    },
    customerUpdated() {
      this.updateCustomer = false
    },
    setFormValidity(value) {
      this.customerFormValid = value
    },
    // TODO
    /* addSubscriptionDialog() {
      this.$store.commit('updateDataToMutateDialog', {
        props: {
          value: {
            customerName: this.customer.address.name_line,
            customerPhone: this.customer.phone,
            customerEmail: this.customer.email
          },
        },
        component: 'components/subscription/subscriptionForm',
        title: this.$t('createSubscription')
      })
    },
    addOrderDialog() {
    this.$store.commit('updateDataToMutateDialog', {
        props: {
          value: {
            customerName: this.customer.address.name_line,
            customerPhone: this.customer.phone,
            customerEmail: this.customer.email
          },
        },
        component: 'components/subscription/orderForm',
        title: this.$t('createSubscription')
      })
    } */
  },
  watch: {
    customer(newValue) {
      if(newValue !== null) {
        this.resetCustomerData()
        this.getCustomerData(newValue)
      }
    },
  },
  mounted() {
    if(this.customer === null && this.uuid !== null) {
      this.getCustomer()
    }
  }
}
</script>

<style>
.customer-toolbar {

}
</style>
