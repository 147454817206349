<template>
  <div>
    <ActionList
      :key="'key'"
      :filters="filters"
      :requires-remote-filtering="true"
      :fixed-filters="fixedFilters"
      :sort-list-items-callback="sortPayments"
      :load-items-callback="loadItemsCallback"
      @actionSuccess="loadItemsCallback"
      @actionFailure="loadItemsCallback"
      empty-icon="mdi-credit-card-outline"
      :empty-text="$t('noPaymentsFound')"
      v-on="$listeners"
      db-table="payments"
      :loading="loading"
      :actions="actions"
      :show-search="showSearch"
    >
      <template v-slot:item="{ item, index }">
        <PaymentListItem
          :payment="item"
          :key="item.uuid"
          v-on="$listeners"
          @deleted="paymentIsDeleted"
        />
        <v-divider
          inset
          :key="index"
        />
      </template>
    </ActionList>
  </div>
</template>

<script>

  import PaymentListItem from "../components/PaymentListItem"
  import ActionList from "./common/list/ActionList"

  export default {
    name: 'PaymentList',
    props: {
      payments: {
        type: Array,
        default: () => null
      },
      loadItemsCallback: {
        type: Function,
        default: () => null
      },
      fixedFilters: {
        type: Array,
        default: () => []
      },
      loading: {
        type: Boolean,
        default: () => false
      },
      showSearch: {
        type: Boolean,
        default: () => true
      }
    },
    components: {
      PaymentListItem,
      ActionList
    },
    data() {
      return {
        startIndex: 0,
        isLoadingMore: false,
        sort: 'desc',
        limit: 1000
      }
    },
    computed: {
      channelOptions() {
        return this.$store.state.channels.filter(notNull => notNull).map(channel => {
          return {
            value: channel.uuid,
            text: channel.label
          }
        })
      },
      actions() {
        return this.$store.getters.bulkOperationsByRoute('payments')
      },
			filters() {
				return [
					{
            key: 'created',
            label: this.$t('dateCreated'),
            type: 'datetime',
            value: [
              0,
              0
            ],
            operator: 'between'
          },
          {
            key: 'amount',
            label: this.$t('amount'),
            type: 'number',
            value: null,
            operator: 'equals'
          },
          {
            key: 'remoteId',
            label: this.$t('remoteId'),
            type: 'text',
            value: null,
            operator: 'contains'
          },
          {
            key: 'currency_code',
            label: this.$t('currency'),
            type: 'select',
            value: null,
            items: ['ISK', 'USD', 'EUR', 'SEK'],
            operator: 'contains'
          },
          {
            key: 'namespace',
            label: this.$t('type'),
            type: 'select',
            value: null,
            items: ['cash', 'verifone', 'ingenico', 'aur', 'siminn_lettkaup', 'giftcard'],
            operator: 'contains'
          },
					{
            key: 'channel.uuid',
            label: this.$t('channel'),
            type: 'select',
            value: null,
            items: this.channelOptions,
            operator: 'contains'
          },
          {
            key: 'orderId',
            label: this.$t('orderId'),
            type: 'text',
            value: null,
            operator: 'equals'
          }
        ]
      }
    },
    methods: {
			paymentIsDeleted(deletedPayment) {
				this.key = Date.now()
			},
      sortPayments(c1, c2) {
		    return c1.created < c2.created
      },
      getPayments(){
        this.$store.dispatch('getSubscriptions')
      },
    }
  }
</script>
