<template>
  <v-list-item
    v-if="isDeleted === false"
    @click="$emit('selectPayment', payment)"
    :key="payment.uuid"
    three-line
  >
    <v-list-item-icon>
      <v-icon
        large
        v-if="payment.status === 'success'"
        color="green"
      >
        mdi-check-circle
      </v-icon>
      <v-icon
        large
        v-if="payment.status === 'failure'"
        color="red"
      >
        mdi-alert-circle
      </v-icon>
      <v-icon
        large
        v-if="payment.status === 'pending'"
        color="blue"
      >
        mdi-dots-horizontal-circle
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title
        class="d-flex"
        v-if="payment.amount >= 0"
      >
        <span class="green--text font-weight-black grow">{{ formattedAmount }}</span><span class="grow" /><span
          class="font-weight-black grey--text"
          v-if="customerName !== ''"
        > {{ customerName }}</span>
      </v-list-item-title>
      <v-list-item-title
        v-if="payment.amount < 0"
      >
        <span class="red--text font-weight-black">{{ formattedAmount }}</span><span class="grow" /><span
          class="font-weight-black grey--text"
          v-if="customerName !== ''"
        > {{ customerName }}</span>
      </v-list-item-title>
      <v-list-item-subtitle><span class="text--primary">{{ title }}</span> &mdash; {{ payment.created | moment('DD/MM/YYYY, HH:mm:ss') }}</v-list-item-subtitle>
      <v-list-item-subtitle v-if="payment.status !== 'success'">
        {{ payment.message }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="payment.namespace === 'cash'">
      <v-btn
        :loading="isDeleting"
        :disabled="isDeleting"
        icon
        @click.stop="deletePayment"
      >
        <v-icon color="delete">
          mdi-close-circle
        </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  export default {
    name: 'PaymentListItem',
    props: {
      payment: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
			isDeleting: false,
			isDeleted: false
    }),
    computed: {
      customerName() {

        if( this.payment.customer === null || this.payment.customer === undefined || this.payment.customer.address === null || this.payment.customer.address.name_line === null) {
          return ''
        }

        return this.payment.customer.address.name_line
      },
      title() {

        if(this.payment.instance === null || this.payment.instance === undefined || this.payment.instance.title === null || this.payment.instance.title == undefined) {
          return this.payment.namespace
        }

        return this.payment.instance.title
      },
      paymentMethod: function() {
        const namespace = "valitor_greidslugatt"
        return this.$store.state.paymentMethods.filter(paymentMethod => namespace === paymentMethod.name)
      },
      formattedAmount() {

        return this.$store.getters.formatAmountByCurrency(this.payment)

        // if(this.payment.currency_code !== 'ISK') {
        //   return new Intl.NumberFormat('is-IS', { style: 'currency', currency: this.payment.currency_code }).format((this.payment.amount / 100))
        // }
        //
        // return new Intl.NumberFormat('is-IS', { style: 'currency', currency: this.payment.currency_code }).format(this.payment.amount)
      }
    },
		methods: {
			deletePayment() {
				this.isDeleting = true
				this.$store.dispatch('deletePayment', this.payment).then(result => {
					if(result !== null && result !== undefined && result.success === true) {
						this.isDeleted = true
					}
				}).finally(() => {
					this.isDeleting = false
				})
			}
		}
  }
</script>
