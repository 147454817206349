<template>
  <div>
    <div>
      <ActionList
        :filters="filters"
        :actions="actions"
        @actionSuccess="getCustomers"
        @actionFailure="getCustomers"
		:preloaded-items-list="customers"
        :sort-list-items-callback="sortCustomers"
        :query-list-items-callback="queryCustomers"
        :fixed-filters="fixedFilters"
        :call-to-actions="callToActions"
        :import-data-values="importDataValues"
        :empty-text="$t('noCustomers')"
      >
        <template v-slot:item="{ item, index }">
          <CustomerListItem
            :customer="item"
            :key="index"
            @click="selectCustomer(item)"
          />
          <v-divider inset />
        </template>
      </ActionList>
    </div>
  </div>
</template>

<script>

export default {
	name: 'CustomerList',
	props: {
		customers: {
			type: Array,
			default: () => []
		},
	 	callToActions: {
			type: Array,
			default: () => null,
		},
		fixedFilters: {
			type: Array,
			default: () => []
		},
	},
	components: {
		'ActionList': () => import('@/components/common/list/ActionList.vue'),
    	'CustomerListItem': () => import('@/components/CustomerListItem.vue'),
	},
	data() {
		return {
			tab: null,
			filters: [
				{
					key: 'address.name_line',
					label: this.$t('name'),
					type: 'text',
					value: null,
					operator: 'contains'
				},
				{
					key: 'phone',
					label: this.$t('phone'),
					type: 'text',
					value: null,
					operator: 'contains'
				},
				{
					key: 'ssn',
					label: this.$t('SSN'),
					type: 'text',
					value: null,
					operator: 'contains'
				},
				{
					key: 'email',
					label: this.$t('email'),
					type: 'text',
					value: null,
					operator: 'contains'
				},
				{
					key: 'label',
					label: this.$t('label'),
					type: 'text',
					value: null,
					operator: 'contains'
				},
				{
					key: 'discount',
					label: this.$t('discount'),
					type: 'text',
					value: null,
					operator: 'contains'
				},
				{
					key: 'invoiceable',
					label: this.$t('invoicable'),
					type: 'boolean',
					value: null,
					operator: 'equals'
				},
				{
					key: 'exclusive',
					label: this.$t('exclusive'),
					type: 'boolean',
					value: null,
					operator: 'equals'
				}
			]
		}
	},
  computed: {
		importDataValues() {
			return {
				createCallback: this.createCustomer,
				updateCallback: null,
				defaultCreateObject: {
					email: null,
					phone: null,
					address: {
						name_line: null,
						country: null,
					}
				},
				schema: [
					{
						property: 'ssn',
						title: 'SSN',
						required: false,
						type: 'String',
						defaultValue: null,
						value: null
					},
					{
						property: 'phone',
						title: 'Phone',
						required: false,
						type: 'String',
						defaultValue: null,
						value: null
					},
					{
						property: 'email',
						title: 'Email',
						required: true,
						type: 'email',
						defaultValue: null,
						value: null
					},
					{
						property: 'name_line',
						title: 'Name',
						required: true,
						type: 'name',
						defaultValue: null,
						value: null
					},
					{
						property: 'country',
						title: 'Country',
						required: true,
						type: 'country',
						defaultValue: null,
						value: null
					},
					{
						property: 'label',
						title: 'Label',
						required: false,
						type: 'String',
						defaultValue: null,
						value: null
					},
					{
						property: 'invoiceable',
						title: 'Invoiceable',
						required: false,
						type: 'Boolean',
						defaultValue: null,
						value: null
					}
				]
			}
		},
		actions() {
			// Filtering out the update_customer action because it hasn't been implemented properly in the UI, the method in PAAS should work.
			return this.$store.getters.bulkOperationsByRoute('customers').filter((action) => {
				return action.namespace !== 'update_customer'
			})
		}
  },
  methods: {
		async createCustomer(customer) {
			try{
				return await this.$store.dispatch('createCustomer', customer)
			} catch (err) {
				console.error('[CustomerList] createCustomer', err)
				throw new Error(`Could not create customer '${customer.email}' because of error: ${err.message || 'Unknown error'}`)
			}
		},
	queryCustomers: (query, customers) => {
		return customers.filter(function (customer) {

			if(customer.address && customer.address.name_line) {
				if(customer.address.name_line.toLowerCase().includes(query.toLowerCase())) {
					return true
				}
			}

			if(customer.phone && customer.phone.includes(query)) {
				return true
			}

			if(customer.email && customer.email.includes(query)) {
				return true
			}

			if(customer.ssn) {
				var ssnQuery = query
				ssnQuery = ssnQuery.replace('-', '')

				if(customer.ssn.includes(ssnQuery)) {
					return true
				}
			}

			if(customer.label && customer.label.includes(query)) {
				return true
			}

			return false
		})
	},
	sortCustomers(c1, c2) {
		return ('' + c1.address.name_line).localeCompare(c2.address.name_line)
	},
	selectCustomer(customer) {
		this.$emit('selectedCustomer', customer)
	},
    getCustomers() {
		this.$emit('loadItems', {})
    }
  }
}
</script>
