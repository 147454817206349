<template>
  <div>
    <div
      v-if="showBookingStats && bookingsQuantity < 1000"
      class="d-flex"
    >
      <v-card width="30%">
        <v-card-text>
          <div class="d-flex justify-space-between">
            <div>
              {{ $t('Total') }}
            </div>
            <h3>
              {{ bookingsQuantity }}
            </h3>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="inContextOverlay">
      <v-card flat>
        <v-btn
          text
          block
          class="text-none"
          @click="$emit('close')"
        >
          {{ $t('close') }}
        </v-btn>
      </v-card>
    </div>
    <ActionList
      :filters="filters"
      :actions="actions"
      :preloaded-items-list="bookings"
      :load-items-callback="loadItemsCallback"
      :fixed-filters="fixedFilters"
      :empty-text="$t('noBookingsFound')"
      :call-to-actions="callToActions"
      :show-search="showSearch"
    >
      <template v-slot:item="{ item, index }">
        <v-list-item @click="selectBooking(item)">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.quantity }}x {{ item.item.title }}
              <span v-if="!showBookingStats">
                <v-chip
                  v-if="item.status === '0' || item.status === false"
                  x-small
                  color="red"
                  label
                  class="ml-2"
                  outlined
                >
                  {{ $t('cancelled') }}
                </v-chip>
                <v-chip
                  v-else-if="currentBookingState(item)"
                  x-small
                  label
                  class="ml-2"
                  :color="currentBookingState(item).color"
                  :dark="!currentBookingColorIsLight(item)"
                >
                  {{ currentBookingState(item).text }}
                </v-chip>
              </span>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ customerName(item) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ $t('arrival:') }} {{ arrivalDateTime(item) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="item.externalNote"
              class="font-italic"
            >
              <span style="font-weight: 700;">
                {{ $t('noteFromCustomer') }}:
              </span>
              <span>
                {{ item.externalNote }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="item.internalNote"
              class="font-italic"
            >
              <span>
                <span style="font-weight: 700;">
                  {{ $t('note') }}:
                </span>
                <span>
                  {{ item.internalNote }}
                </span>
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action-text v-if="Array.isArray(item.locationVariations) && item.locationVariations.length > 0">
            <v-chip
              v-for="locationVariation in item.locationVariations"
              :key="locationVariation.label"
              x-small
              class="mr-1"
            >
              {{ locationVariation.label }}
            </v-chip>
          </v-list-item-action-text>
          <v-list-item-action
            v-if="showBookingStats"
            class="caption"
          >
            {{ item.status }}
          </v-list-item-action>
        </v-list-item>
        <v-divider
          :key="index"
        />
      </template>
    </ActionList>
  </div>
</template>

<script>

import ActionList from '@/components/common/list/ActionList'

export default {
	name: 'BookingListSimple',
  props: {
      bookings: {
        type: Array,
        default: () => []
      },
      filters: {
        type: Array,
        default: () => []
      },
      loadItemsCallback: {
        type: Function,
        default: null
      },
      fixedFilters: {
        type: Array,
        default: () => []
      },
      callToActions: {
        type: Array,
        default: () => []
      },
		showSearch: {
			type: Boolean,
			default: true
		},
	  showBookingStats: {
		  type: Boolean,
		  default: false
	  }
  },
	data() {
		return {
			inContextOverlay: false
		}
	},
  components: {
    ActionList
  },
	methods: {
    ticketUrl(booking) {
      return 'https://ticket.salescloud.is?uuid=' + booking.uuid
    },
    dateParse(secondString) {
        if(secondString.toString().length === 10) {
            return new Date(secondString * 1000)
        }
        else {
            return new Date(secondString)
        }
    },
    selectBooking(booking){
        this.$emit('selectBooking', booking)
    },
    customerName(booking) {
        if(booking.customer !== null && booking.customer !== undefined && booking.customer.address !== null && booking.customer.address !== undefined && booking.customer.address.name_line !== null && booking.customer.address.name_line !== undefined) {
            return booking.customer.address.name_line
        }
        return this.$t('noName')
    },
		arrivalDateTime(booking) {
			return new Date(this.$store.getters.validateAndGetEpochTimestampInMillis(booking.startsAtTime)).toISOString().replace('T', ' ').substring(0, 19)
		},
		currentBookingState(item) {
			if(typeof item !== 'undefined' && item !== null) {
				if(typeof item.state !== 'undefined' && item.state !== null) {
					if(Array.isArray(this.availableBookingStates) && this.availableBookingStates.length > 0) {
						let currentBookingState = this.availableBookingStates.find(state => state && state.value === item.state)
						if(typeof currentBookingState === 'undefined' || currentBookingState === null) {
							if(item.status === 1 || item.status === '1' || item.status === true) {
								currentBookingState = this.availableBookingStates.find(state => state && state.value === 'active')
							}
						}
						return currentBookingState
					}
				} else {
					return this.availableBookingStates.find(state => state && state.value === 'active')
				}
			}
			return null
		},
		currentBookingColorIsLight(item) {
			if(this.currentBookingState(item)) {
				return this.$store.getters.colorIsLight(this.currentBookingState(item).color)
			}
			return false
		},
	},
	computed: {
		availableBookingStates() {
			return this.$store.state.availableBookingStates
		},
		actions() {
			return this.$store.getters.bulkOperationsByRoute('items/:uuid#bookings')
		},
		bookingsQuantity() {
			if(!Array.isArray(this.bookings)) {
				return 0
			}
			return this.bookings.reduce((total, booking) => {
				if(typeof booking !== 'undefined' && booking !== null) {
					if(typeof booking.quantity !== 'undefined' && booking.quantity !== null && !isNaN(Number(booking.quantity))) {
						return total + Number(booking.quantity)
					}
					return total + 1
				}
				return total
			}, 0)
		}
	},
	mounted() {
		this.inContextOverlay = false
		try {
			// Set to show close button
			const el = document.getElementById('context-overlay')
			if(el) {
				this.inContextOverlay = true
			}
		} catch (e) {
			// Nothing to do
		}

	}
}
</script>
