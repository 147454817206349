<template>
  <div>
    <div>
      <ActionList
        :preloaded-items-list="formsOfPayment"
        :filters="filters"
        :actions="actions"
        :call-to-actions="callToActions"
        @actionSuccess="getFormsOfPayment"
        @actionFailure="getFormsOfPayment"
        db-table="formsOfPayment"
        :load-items-callback="loadItemsCallback"
        :fixed-filters="fixedFilters"
        :empty-text="$t('noFormsOfPaymentFound')"
        :import-data-values="importDataValues"
      >
        <template v-slot:item="{ item, index }">
          <FormOfPaymentListItem
            :key="item.uuid"
            :form-of-payment="item"
            @selectFormOfPayment="$emit('selectFormOfPayment', item)"
          />
          <v-divider
            :key="index"
            inset
          />
        </template>
      </ActionList>
      <div v-intersect.quiet="loadMore" />
    </div>
  </div>
</template>

<script>

  import FormOfPaymentListItem from "./FormOfPaymentListItem"
  import ActionList from "../common/list/ActionList"

  export default {
    name: 'FormOfPaymentList',
    props: {
      loadItemsCallback: {
        type: Function,
        default: () => null
      },
      fixedFilters: {
        type: Array,
        default: () => []
      },
      callToActions: {
        type: Array,
        default: () => []
      },
      formsOfPayment: {
        type: Array,
        default: () => []
      }
    },
    components: {
      FormOfPaymentListItem,
      ActionList
    },
    data() {
      return {
        startIndex: 0,
        length: 50,
        step: 50,
        isLoadingMore: false,
        filters: [
          {
            key: 'amount',
            label: this.$t('amount'),
            type: 'number',
            value: null,
            operator: 'equals'
          },
          {
            key: 'created',
            label: this.$t('dateCreated'),
            type: 'datetime',
            value: [
              0,
              0
            ],
            operator: 'between'
          },
          {
            key: 'customer.address.name_line',
            label: this.$t('customerName'),
            type: 'text',
            value: null,
            operator: 'contains'
          },
          {
            key: 'customer.email',
            label: 'Customer email',
            type: 'text',
            value: null,
            operator: 'contains'
          },
					{
						key: 'item.title',
						label: this.$t('itemTitle'),
						type: 'text',
						value: null,
						operator: 'contains'
					}
        ]
      }
    },
    computed: {
			importDataValues() {
				return {
					createCallback: this.createGiftcard,
					updateCallback: null,
					defaultCreateObject: {

					},
					schema: [
						{
							property: 'remoteID',
							title: 'Code',
							required: true,
							type: 'String',
							defaultValue: null,
							value: null
						},
						{
							property: 'amount',
							title: 'Amount',
							required: true,
							type: 'Float',
							defaultValue: null,
							value: null
						},
						{
							property: 'item',
							title: 'Item',
							required: true,
							type: 'String',
							defaultValue: null,
							value: null
						},
						{
							property: 'expiryMonth',
							title: 'Expiry Month',
							required: true,
							type: 'String',
							defaultValue: null,
							value: null
						},
						{
							property: 'expiryYear',
							title: 'Expiry Year',
							required: true,
							type: 'String',
							defaultValue: null,
							value: null
						},
					]
				}
			},
      slicedItems() {
        return this.formsOfPayment.slice(0, this.length)
      },
      actions() {
        return this.$store.getters.bulkOperationsByRoute('forms-of-payment')
      }
    },
    methods: {
			createGiftcard(value) {
				return this.$store.dispatch('createGiftcard', {fop: value})
			},
      queryFormsOfPayment(query, formOfPayment) {
        if(formOfPayment && formOfPayment.customer && formOfPayment.customer.address && formOfPayment.customer.address.name_line) {
          if(formOfPayment.customer.address.name_line.includes(query)) {
            return true
          }
        }

        if(formOfPayment && formOfPayment.remoteID && formOfPayment.remoteID.includes(query)) {
          return true
        }

        return false
      },
      getFormsOfPayment({limit, skip, conditions}) {
        return this.$store.dispatch('getFormsOfPayment', {limit: limit, conditions})
      },
      loadMore: function() {
        this.length = this.length + this.step
      }
    }
  }
</script>
