<template>
  <v-list-item @click="$emit('selectFormOfPayment', formOfPayment)">
    <v-list-item-content>
      <v-list-item-title :class="formOfPayment.remoteID ? '' : 'font-italic' ">
        {{ formOfPayment.remoteID ? formOfPayment.remoteID : $t('codeRequired') }}
      </v-list-item-title>
      <v-list-item-subtitle><span :class="customer.nameStyle">{{ customer.name }}</span> &mdash; {{ paymentMethod }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
    name: 'FormOfPaymentListItem',
    props: {
      formOfPayment: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
        customer() {
            if(!this.formOfPayment.customer || !this.formOfPayment.customer.address || !this.formOfPayment.customer.address.name_line) {
                return {name: this.$t('customerNameNA'), nameStyle:'font-italic'}
            }
            return {name: this.formOfPayment.customer.address.name_line, nameStyle:'text--primary'}
        },
        organization() {
            return this.$store.state.organization
        },
        primaryColor() {
            return this.$store.getters.primaryColor
        },
        cardType() {
            return this.formOfPayment.cardType || 'N/A'
        },
        paymentMethod() {
            return this.formOfPayment.paymentMethod.split('_').join(' ')
        },
        color() {
            let color = ''

            switch(this.cardType) {
                case 'giftcard':
                    color = this.primaryColor
                    break
                case 'Gift card':
                    color = this.primaryColor
                    break
                case 'Visa':
                    color = '#1A1F71'
                    break
                case 'MasterCard':
                    color = '#EB001B'
                    break
                case 'Amex':
                    color = '#026FD1'
                    break
            }

            return color
        }
    }
}
</script>
