<template>
  <div>
    <v-list-item
      three-line
      :key="subscription.uuid"
      link
      @click="$emit('selectSubscription', subscription)"
    >
      <v-list-item-avatar
        class="white--text"
        :color="status.color"
        style="text-align: center;"
      >
        <v-icon color="white">
          {{ status.icon }}
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ subscription.payer.address.name_line }}</v-list-item-title>
        <v-list-item-subtitle><span class="text--primary">{{ subscription.units }} x {{ subscription.item.title }} @ {{ formattedPrice }} </span></v-list-item-subtitle>
        <v-list-item-subtitle>{{ $t('nextPayment') }}: {{ subscription.nextPayment | moment('DD/MM/YYYY') }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-chip v-if="subscription.cycles > 0">
          {{ subscription.cycle }} / {{ subscription.cycles }}
        </v-chip>
        <v-chip v-else>
          {{ subscription.cycle }} / &infin;
        </v-chip>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
  export default {
    name: 'SubscriptionListItem',
    props: {
      subscription: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      now: new Date
    }),
    computed: {
      status() {
        if(!this.subscription.active){
          return {color: 'red', icon: 'mdi-close', }
        }

        if(this.subscription.expires && this.subscription.expires < this.now) {
          return {color: 'red', icon: 'mdi-clock-alert'}
        }

        if(this.subscription.nextPayment < this.now){
          return {color: 'orange lighten-3', icon: 'mdi-alert', }
        }
       
        if(this.subscription.paused) {
          return {color: 'blue', icon: 'mdi-pause'}
        }

        return {color: 'green', icon: 'mdi-check'}
      },
      formattedPrice() {

        return this.$store.getters.formatAmountByCurrency(this.recurringPrice)

        // if(this.recurringPrice.currency_code !== 'ISK') {
        //   return new Intl.NumberFormat('is-IS', { style: 'currency', currency: this.recurringPrice.currency_code }).format((this.recurringPrice.amount / 100))
        // }
        //
        // return new Intl.NumberFormat('is-IS', { style: 'currency', currency: this.recurringPrice.currency_code }).format(this.recurringPrice.amount)
      },
      recurringPrice() {
        if(this.subscription.recurringPrice !== null && this.subscription.recurringPrice !== undefined) {
          return this.subscription.recurringPrice
        }

        return this.subscription.item.price
      }
    }
  }
</script>
